<template>
  <div>
    <v-progress-linear indeterminate v-if="fetching"></v-progress-linear>
    <v-form ref="form" v-model="valid">
      <v-row dense align="center">
        <v-col cols="6">
          <span class="radio-title"> Ownership </span>
          <v-radio-group
            v-model="formData.type"
            row
            v-if="landTypes"
            class="mb-0 mt-0"
            :rules="rules.requiredRule"
          >
            <v-radio
              class="mb-1"
              v-for="land in landTypes"
              :key="land.value"
              :label="land.text"
              :value="land.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6">
          <span class="radio-title"> Binder </span>
          <v-radio-group
            v-model="formData.binder"
            row
            v-if="binders"
            class="mb-0 mt-0"
            :rules="rules.requiredRule"
          >
            <v-radio
              class="mb-1"
              v-for="binder in binders"
              :key="binder.value"
              :label="binder.text"
              :value="binder.value"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" class="mt-n2 mb-n2">
          <v-textarea
            v-model="formData.binderNotes"
            rows="1"
            auto-grow
            outlined
            label="Binder Notes"
          ></v-textarea>
        </v-col>

        <v-col cols="6">
          <v-radio-group v-model="formData.status" row v-if="landTypes" class="mt-0">
            <v-radio
              v-for="land in landStatus"
              :key="land.value"
              :label="land.text"
              :value="land.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6">
          <v-checkbox v-model="formData.trails" label="Trails"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6">
          <DateField label="Date Acquired" v-model="formData.dateAcquired" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined label="Grantor" v-model="formData.grantor"></v-text-field>
        </v-col>
        <v-col cols="12" v-if="!emptyContact()">
          <v-btn
            color="primary"
            text
            class="text-none mt-n3 mb-2"
            :class="{ 'v-btn--active': showContact }"
            @click="showContact = !showContact"
          >
            Contact
            <v-icon class="mx-1"> mdi-card-account-details </v-icon>
          </v-btn>
          <v-slide-y-transition>
            <div v-show="showContact">
              <Contact
                outlined
                v-model="contact"
                :format-phone-number="$store.state.organizationSetting.formatPhone"
                :readonly="!$store.state.permissions.create"
                ref="contact"
                :exclude="['company', 'bio', 'photo', 'tags']"
              >
                <template #header="{ contactDialogs }">
                  <v-dialog v-model="contactDialogs.email" max-width="400">
                    <GrantorEmail
                      :key="contactDialogs.email"
                      @success="contactDialogs.email = false"
                      v-model="contactDialogs.email"
                      :parcel-id="id"
                    />
                  </v-dialog>
                </template>
              </Contact>
              <v-btn text color="primary" class="text-none mb-3 mt-n1" @click="showContact = false"
                >Hide contact <v-icon>mdi-chevron-up</v-icon></v-btn
              >
            </div>
          </v-slide-y-transition>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            label="Acres Upland"
            type="number"
            v-model.number="formData.acresUpland"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            label="Acres Wetland"
            type="number"
            v-model.number="formData.acresWetland"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Primary Habitats"
            outlined
            v-model="formData.primaryHabitats"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="formData.comment"
            label="Comment"
            outlined
            rows="2"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="6" v-show="formData.type === 'cr'">
          <v-text-field outlined label="CR Reference" v-model="formData.crReference"></v-text-field>
        </v-col>
        <v-col cols="6" v-show="formData.type === 'cr'">
          <v-text-field outlined label="CR Purpose" v-model="formData.crPurpose"></v-text-field>
        </v-col>
        <v-col cols="4" v-if="preserves.length">
          <v-select
            clearable
            v-model="formData.preserve"
            label="Preserve"
            outlined
            :items="preserves"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            clearable
            v-model="formData.monitoringClass"
            label="Monitoring"
            outlined
            :items="monitoringClasses"
          ></v-select>
        </v-col>
        <v-col cols="4" v-if="$store.getters.parcelSetting.team">
          <ParcelTeamField :parcel-id="$route.params.id" ref="parcelTeam" />
        </v-col>
      </v-row>
      <BaseFieldset :outlined="true" :class="`mb-2 ${expand ? '' : 'fieldset--disabled'}`">
        <legend class="cursor-pointer" @click="expand = !expand">
          More
          <v-icon color="primary">{{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </legend>
        <v-slide-y-transition>
          <div v-show="expand">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  label="Trust Deed"
                  v-model="formData.trustDeed"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Orig Deed" v-model="formData.origDeed"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  label="Plan Bk/Pg; Lot"
                  v-model="formData.plan"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  label="Acquistion Value"
                  v-model="formData.acquistionValue"
                ></v-text-field>
              </v-col>
            </v-row></div
        ></v-slide-y-transition>
      </BaseFieldset>
      <v-alert type="error" text v-if="error">
        {{ error }}
      </v-alert>
      <div class="text-center">
        <FormAction
          icon="mdi-content-save"
          :loading="loading"
          :x-large="false"
          :update="update"
          :disable-submit="!permissions.create"
          @cancel="onCancel"
          @click="onSubmit"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import Land from '../../services/land';
const land = new Land();
import rulesMixin from '../../mixins/rules';
import userMixin from '../../mixins/user';
import { mapState } from 'vuex';
import Contact from '../../services/contact';
const c = new Contact();
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  mixins: [rulesMixin, userMixin],
  components: {
    DateField: () => import('@/components/Core/DateField.vue'),
    BaseFieldset: () => import('@/components/Core/Base/Fieldset.vue'),
    FormAction: () => import('@/components/Feature/FormAction.vue'),
    ParcelTeamField: () => import('@/components/Parcel/TeamField.vue'),
    Contact: () => import('../../components/Contact.vue'),
    GrantorEmail: () => import('./GrantorEmail.vue')
  },
  data() {
    return {
      formData: {},
      valid: true,
      landTypes: null,
      landStatus: null,
      expand: false,
      loading: false,
      error: '',
      fetching: false,
      monitoringClasses: ['A', 'B', 'C', 'D'],
      binders: land.BINDER,
      showContact: false,
      contact: null
    };
  },
  computed: {
    ...mapState({ preserves: 'landPreserves', permissions: 'permissions' })
  },
  methods: {
    emptyContact() {
      if (!this.contact || this.$store.getters.isAdmin || this.$store.state.permissions.create) {
        return;
      }
      const c = new Contact();
      return c.setData(this.contact).isEmpty();
    },
    getData() {
      this.fetching = true;
      land
        .detail(this.id)
        .then(res => {
          this.formData = { ...res };
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    getContactData() {
      c.get(this.id).then(res => {
        if (res) {
          this.contact = res.data;
        }
      });
    },
    onCancel() {
      this.$emit('cancel');
    },
    updateContact() {
      const uid = this.$store.state.user.uid;
      const { type, id } = this.$store.state.loggedInAs;
      let orgId = '';
      if (type === 'organization') {
        orgId = id;
      }
      const contactData = {
        ...this.$refs.contact.getData(),
        parcel: this.id,
        user: uid,
        organization: orgId
      };
      c.update(contactData, this.id);
    },
    onSubmit() {
      this.updateContact();
      this.$refs.form.validate();
      try {
        this.$refs.parcelTeam.save();
      } catch {
        //
      }
      if (!this.valid) {
        return;
      }
      this.loading = true;
      this.error = '';
      land
        .save(this.id, this.formData)
        .then(() => {
          const action = {
            feature: 'land_information',
            action: this.update ? 'update' : 'create'
          };
          this.$emit('success', action);
        })
        .catch(error => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.landTypes = land.LAND_TYPES;
    this.landStatus = land.LAND_STATUS;
    this.getData();
    this.getContactData();
  }
};
</script>

<style scoped lang="scss">
.radio-title {
  font-size: 20px;
}
</style>
